import React from 'react';
import Copyright from './Copyright';

export default class WelcomeCta extends React.Component {

    handleClickEmployee() {
        this.setState({
            loaded: true, //CHANGE TO FALSE FOR LOADING SCREEN!!!!!!!!
            loggedIn: true,
            userName: "Employee",
            userType: "employee_dashboard"
        });
        this.setState({ state: this.state });


    }

    handleClickCustomer() {
        this.setState({
            loggedIn: true,
            userName: "Customer",
            userType: "customer_dashboard"
        });
        this.setState({ state: this.state });
    }
    render() {
        const title = this.props.title;
        const sub_text = this.props.sub_text;

        return (
            <div className="welcome-screen">
                <div className="ws-content" >
                    <div className="logo-container">
                        <img className="logo" src={require('../assets/images/logo.png')} alt="Victaulic" ></img>
                    </div>
                    <div className="content-container" >
                        <h1>{title}</h1>
                        <p>
                            {sub_text}
                        </p>
                    </div>
                    <div className="login-cta-container">
                        <button className="welcome-screen-cta" onClick={(e) => this.handleClickEmployee(e)}>
                            I'm a Victaulic <strong>Employee</strong>
                        </button>
                        <button className="welcome-screen-cta" onClick={(e) => this.handleClickCustomer(e)}>
                            I'm a <strong>Customer / Distributor</strong>
                        </button>
                    </div>
                    <Copyright />
                </div>
            </div>
        );
    }
}