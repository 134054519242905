import React from "react";
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import UserInterface from './UserInterface';
import LoadingScreen from './LoadingScreen';
import WelcomeCta from './WelcomeCta';
import { authProvider } from './authProvider';

export default class Authentication extends React.Component {

    render() {
        const dashData = this.props.dashData;
        const state = this.props.state;

        return (
            <AzureAD provider={authProvider} forceLogin={true} >
                {
                    ({ login, logout, authenticationState, error, accountInfo }) => {
                        switch (authenticationState) {
                            case AuthenticationState.Authenticated:
                                return (
                                    <UserInterface dashData={dashData}
                                        state={state}
                                        login={login}
                                        logout={logout}
                                        authenticationState={authenticationState}
                                        error={error}
                                        accountInfo={accountInfo}
                                    />
                                );

                            case AuthenticationState.Unauthenticated:
                                return (
                                    <div>
                                        {
                                            error && <p>
                                                <span>An error occured during authentication, please try again!</span>
                                                <button style={{ color: "white" }} onClick={login}> Login</button>
                                            </p>
                                        }
                                        <UserInterface state={{
                                            loggedIn: true,
                                            userName: "Customer",
                                            userType: "customer_dashboard"
                                        }}
                                            login={login}
                                            logout={logout}
                                            authenticationState={authenticationState}
                                            error={error}
                                            accountInfo={accountInfo}
                                            dashData={dashData} />
                                    </div>
                                );
                            case AuthenticationState.InProgress:
                                return (
                                    <LoadingScreen />
                                );

                            default:
                                return (
                                    <WelcomeCta title="Welcome to Victaulic" sub_text="Please login to continue" />
                                );
                        }
                    }
                }
            </AzureAD>
        );
    }
}
